import React from 'react'
import Slider from 'react-slick'
import { H2, Subtitle } from '../elements/Titles'
import { UpDown, UpDownWide } from '../styles/animations'

import Content from '../elements/Content'
import ContentGrid from '../elements/ContentGrid'
import { Divider } from '../elements/Dividers'
import Image from '../elements/Image'
import ImageContainer from '../elements/ImageContainer'
import ImageDouble from '../elements/ImageDouble'
import Inner from '../elements/Inner'
import SVG from '../components/SVG'
// import { useStaticQuery, graphql } from 'gatsby'
import TextContainer from '../elements/TextContainer'
import { colors } from '../../tailwind'
import room from '../images/isorooms/room.png'
import roomsIntro02 from '../images/isorooms/rooms_intro_02.png'

const Keypoints = ({ slickSettings, offset }) => (
  <div>
    <Divider speed={0.2} offset={offset}>
      {/* <UpDown>
        <SVG icon="triangle" hiddenMobile width={48} stroke={colors.orange} left="10%" top="20%" />
        <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} fill={colors['blue-dark']} left="80%" top="10%" />
        <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
        <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="75%" top="10%" />
        <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="triangle" width={8} stroke={colors['grey-darker']} left="25%" top="5%" />
      <SVG icon="circle" width={64} fill={colors.green} left="95%" top="5%" />
      <SVG icon="box" hiddenMobile width={64} fill={colors.purple} left="5%" top="90%" />
      <SVG icon="box" width={6} fill={colors['grey-darkest']} left="10%" top="10%" />
      <SVG icon="hexa" width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="80%" top="70%" /> */}
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Inner variant="clean">
        <Slider {...slickSettings}>
          <div>
            <ContentGrid>
              <ImageContainer>
                <Image img={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H2>Points forts</H2>
                <Subtitle>Un système complet salle/Game-master.</Subtitle>
                <Subtitle>Intégration complète, maîtrisée.</Subtitle>
                <Subtitle>Conception industrielle.</Subtitle>
                <Subtitle />
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomsIntro02} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H2>Contrôle total</H2>
                <Subtitle>Le système suit la partie pour le Game-master.</Subtitle>
                <Subtitle>La progression est affichée sur l'écran.</Subtitle>
                <Subtitle>L'ensemble des énigmes est sous contrôle.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
        </Slider>
      </Inner>
    </Content>
  </div>
)

export default Keypoints
