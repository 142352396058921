import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Inner from '../elements/Inner'
import ContentGrid from '../elements/ContentGrid'
import TextContainer from '../elements/TextContainer'
import ImageContainer from '../elements/ImageContainer'
import Image from '../elements/Image'
import { Divider } from '../elements/Dividers'
import Content from '../elements/Content'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

import moreinfos from '../images/illu/moreinfos.png'

const MoreInfos = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "moreinfos" } }) {
          html
        }
      }
    `
  )
  return (
    <div>
      <Divider speed={0.2} offset={offset}>
        <UpDown>
          <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
          <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} fill={colors['blue-dark']} left="80%" top="10%" />
          <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
          <SVG icon="triangle" width={16} stroke={colors['grey-darkest']} left="30%" top="65%" />
        </UpDownWide>
        <SVG icon="circle" hiddenMobile width={24} fill={colors['grey-darker']} left="5%" top="70%" />
        <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="50%" top="60%" />
        <SVG icon="upDown" width={8} fill={colors['grey-darkest']} left="95%" top="90%" />
      </Divider>
      <Content speed={0.4} offset={offset}>
        <Inner variant="clean">
          <ContentGrid>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.html,
                }}
              />
            </TextContainer>
            <ImageContainer>
              <Image img={moreinfos} size="fluid" />
            </ImageContainer>
          </ContentGrid>
        </Inner>
      </Content>
    </div>
  )
}

export default MoreInfos
