import styled, { css } from 'styled-components'

import tw from 'tailwind.macro'
import { colors } from '../../tailwind'

const variants = {
  light: css`
    background-color: rgba(84, 198, 190, 0.8);
    color: ${colors['teal-darkest']};
  `,
  dark: css`
    background-color: rgba(47, 47, 47, 0.8);
    color: ${colors.white};
  `,
  pimp: css`
    background-color: rgba(247, 177, 92, 0.9);
    color: ${colors.white};
  `,
  clean: css`
    background-color: rgba(233, 238, 253, 0.8);
    color: ${colors['teal-darker']};
  `,
}

const Inner = styled.div`
  ${tw`w-full xxl:w-2/3 pt-4 pb-8 px-4 lg:px-8 rounded shadow-md`};
  background-color: rgba(47, 87, 85, 0.8);
  color: ${colors.white};

  ${p => variants[p.variant]};

  ${p => p.centered && tw`text-center`};
`

export default Inner
