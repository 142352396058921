import React, { useState } from 'react'
import Slider from 'react-slick'
import { H2, H3, Subtitle } from '../elements/Titles'
import { UpDown, UpDownWide } from '../styles/animations'

import Content from '../elements/Content'
import ContentGrid from '../elements/ContentGrid'
import { Divider } from '../elements/Dividers'
import Image from '../elements/Image'
import ImageContainer from '../elements/ImageContainer'
import ImageDouble from '../elements/ImageDouble'
import Inner from '../elements/Inner'
import SVG from '../components/SVG'
import ImagesAnim from '../components/ImagesAnim'
import TextContainer from '../elements/TextContainer'
import { colors } from '../../tailwind'
import room from '../images/isorooms/room.png'
import roomAllScreen from '../images/isorooms/rooms_pupitre_allscreen.png'
import roomBase from '../images/isorooms/rooms_base.png'
import roomDoorOk from '../images/isorooms/rooms_pupitre_porteok.png'
import roomFeedback01 from '../images/isorooms/rooms_pupitre_feedback_01.png'
import roomFeedback02 from '../images/isorooms/rooms_pupitre_feedback_02.png'
import roomFeedback03 from '../images/isorooms/rooms_pupitre_feedback_03.png'
import roomSolution01 from '../images/isorooms/rooms_pupitre_solution_01.png'
import roomSolution02 from '../images/isorooms/rooms_pupitre_solution_02.png'
import roomTactile from '../images/isorooms/rooms_pupitre_01.png'
import roomValidation from '../images/isorooms/rooms_pupitre_validation.png'

const Desk = ({ slickSettings, offset }) => (
  <div>
    <Divider speed={0.2} offset={offset}>
      {/* <UpDown>
        <SVG icon="triangle" hiddenMobile width={48} stroke={colors.orange} left="10%" top="20%" />
        <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
        <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} fill={colors['blue-dark']} left="80%" top="10%" />
        <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
        <SVG icon="circle" width={16} fill={colors['grey-darker']} left="70%" top="90%" />
        <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="75%" top="10%" />
      </UpDownWide>
      <SVG icon="circle" hiddenMobile width={24} fill={colors['grey-darker']} left="5%" top="70%" />
      <SVG icon="box" width={12} fill={colors['grey-darkest']} left="40%" top="30%" />
      <SVG icon="hexa" width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="80%" top="70%" /> */}
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Inner variant="clean">
        <Slider {...slickSettings}>
          <div>
            <ContentGrid>
              <ImageContainer>
                <Image img={roomTactile} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H2>Pupitre tactile !</H2>
                <Subtitle>Écran IPS 21.5 pouces.</Subtitle>
                <Subtitle>Dalle tactile multitouch en verre.</Subtitle>
                <Subtitle>Vertical ou horizontal.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomBase} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Un câblage simple et peu onéreux</H3>
                <Subtitle>Un seul câble pour tous les props.</Subtitle>
                <Subtitle>Le pupitre alimente totalement la salle pour 95% des cas.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImagesAnim images={[roomFeedback01, roomFeedback02, roomFeedback03]} bg={room} />
              </ImageContainer>
              <TextContainer>
                <H3>Retour complet de l’état</H3>
                <Subtitle>Un coup d'oeil permet de connaitre la progression des joueurs.</Subtitle>
                <Subtitle>Plus besoin de rester rivé aux caméras, finies les suppositions.</Subtitle>
                <Subtitle>Visualisation instantanée des appuis bouton, éléments RFID en place, sélections...</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomDoorOk} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Contrôle total</H3>
                <Subtitle>Le game master peut changer l'état des props à distance.</Subtitle>
                <Subtitle>Ouvrir une porte, allumer des lampes, activer un vérin, un effet ...</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImagesAnim images={[roomSolution01, roomSolution02, roomFeedback03]} bg={room} />
              </ImageContainer>
              <TextContainer>
                <H3>Aide aux joueurs</H3>
                <Subtitle>Résolution forcée d’une énigme.</Subtitle>
                <Subtitle>Envoi de messages audio ou visuels.</Subtitle>
                <Subtitle>Adaptation du niveau de difficulté.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomValidation} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Check-up</H3>
                <Subtitle>Validation de la préparation de la salle, adieu aux oublis.</Subtitle>
                <Subtitle>Blocage possible de la salle tant qu'elle n'est pas dans un état pré-défini.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomAllScreen} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Tout est sur l’écran</H3>
                <Subtitle>Aucun tableau électrique à manipuler.</Subtitle>
                <Subtitle>Lecture des données des props et validation des ordres envoyés.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
        </Slider>
      </Inner>
    </Content>
  </div>
)

export default Desk
