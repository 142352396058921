import React from 'react'
import Slider from 'react-slick'
import { H2, H3, Subtitle } from '../elements/Titles'
import { UpDown, UpDownWide } from '../styles/animations'

import Content from '../elements/Content'
import ContentGrid from '../elements/ContentGrid'
import { Divider } from '../elements/Dividers'
import ImageContainer from '../elements/ImageContainer'
import ImageDouble from '../elements/ImageDouble'
import Inner from '../elements/Inner'
import SVG from '../components/SVG'
import TextContainer from '../elements/TextContainer'
import { colors } from '../../tailwind'
import room from '../images/isorooms/room.png'
import roomConcept01 from '../images/isorooms/rooms_base.png'
import roomConcept02 from '../images/isorooms/rooms_concept_02.png'
import roomConcept03 from '../images/isorooms/rooms_concept_03.png'

const Room = ({ slickSettings, offset }) => (
  <div>
    <Divider bg={colors.teal} clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={offset} />
    <Divider speed={0.1} offset={offset}>
      {/* <UpDown>
        <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
        <SVG icon="upDown" hiddenMobile width={24} fill={colors.orange} left="80%" top="80%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.purple} left="5%" top="80%" />
        <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
        <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
      </UpDownWide>
      <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
      <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" /> */}
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Inner variant="clean">
        <Slider {...slickSettings}>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomConcept01} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H2>Concept novateur pour les salles</H2>
                <Subtitle>Un seul câble sort du pupitre pour distribuer puissance et réseau, de point en point.</Subtitle>
                <Subtitle>Chaque prop est un mini-ordinateur autonome, capable de réagir à l'environnement et aux actions des autres props.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomConcept02} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>160W pour la salle</H3>
                <Subtitle>Consommation générale d'une salle: 15 à 80W.</Subtitle>
                <Subtitle>Besoin de plus? Contrôle de relais de puissance, injection de courant.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomConcept03} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Un câblage simple et peu onéreux</H3>
                <Subtitle>Il suffit de tirer le câble de bout en bout et couper aux props.</Subtitle>
                <Subtitle>Rajouter un élément consiste juste à refaire un câble entre les deux points les plus proches.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
        </Slider>
      </Inner>
    </Content>
  </div>
)

export default Room
