import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Divider } from '../elements/Dividers'
import Content from '../elements/Content'
import Inner from '../elements/Inner'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

const Citation = styled.div`
  ${tw`text-left text-lg`}
`

const Testimony = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "testimony" } }) {
          html
        }
      }
    `
  )
  return (
    <div>
      <Divider bg={`linear-gradient(to right, ${colors['teal-darker']} 0%, ${colors.teal} 100%)`} clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={offset} />
      <Divider speed={0.1} offset={offset}>
        <UpDown>
          <SVG icon="box" hiddenMobile width={6} fill={colors.blue} left="50%" top="75%" />
          <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
          <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
        <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
      </Divider>
      <Content speed={0.4} offset={offset}>
        <Inner variant="pimp" centered>
          <Citation
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          />
        </Inner>
      </Content>
    </div>
  )
}

export default Testimony
