import styled, { css } from 'styled-components'

import React from 'react'
import tw from 'tailwind.macro'
import { colors } from '../../tailwind'

const variants = {
  padr: css`
    ${tw`lg:pr-6`}
  `,
}

const TextContainer = styled.div`
  ${tw`w-full lg:w-2/4 flex flex-col justify-center items-start lg:pl-8 pb-4`}
  ${p => variants[p.variant]}
  a {
    cursor: pointer;
    color: ${colors['teal-darker']};
    &:hover {
      color: ${colors.black};
      text-decoration: underline;
    }
  }
`

export default TextContainer
