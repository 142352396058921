import { HamburgerSpin } from 'react-animated-burgers'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Parallax } from 'react-spring/renderprops-addons.cjs'
import React from 'react'
import WindowSizeListener from 'react-window-size-listener'
import { globalHistory } from '@reach/router'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { uniqueID } from '../utils/misc'
import CookieConsent from '../components/CookieConsent'
import Controls from '../sections/Controls'
import Desk from '../sections/Desk'
import Extra from '../sections/Extra'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Intro from '../sections/Intro'
import Keypoints from '../sections/Keypoints'
import Layout from '../components/Layout'
import Logo from '../elements/Logo'
import MoreInfos from '../sections/MoreInfos'
import Riddles from '../sections/Riddles'
import Roadmap from '../sections/Roadmap'
import Room from '../sections/Room'
import Security from '../sections/Security'
import Testimony from '../sections/Testimony'
import { colors } from '../../tailwind'

const { hash: hashLocation } = globalHistory.location || null
const sections = [
  {
    name: 'Accueil',
    hash: 'header',
    logo: true,
  },
  {
    name: 'Bard system',
    hash: 'intro',
  },
  {
    name: 'Points forts',
    hash: 'keypoints',
  },
  {
    name: 'Pupitre',
    hash: 'desk',
  },
  {
    name: 'Salle',
    hash: 'room',
  },
  {
    name: 'Énigmes',
    hash: 'riddles',
  },
  {
    name: 'Sécurité',
    hash: 'security',
  },
  {
    name: 'Contrôle',
    hash: 'Controls',
  },
  {
    name: 'Extras',
    hash: 'extra',
  },
  {
    name: 'À venir',
    hash: 'roadmap',
  },
  {
    name: 'Témoignages',
    hash: 'testimony',
  },
  {
    name: 'Contacts',
    hash: 'more',
  },
  // {
  //   name: 'Pied de page',
  //   hash: 'footer',
  // },
]

const Container = styled.div`
  ${tw`font-sans h-screen`};
`
const MobileContainer = styled.div`
  ${tw`flex justify-between`}
`
const Burger = styled(HamburgerSpin)`
  ${tw`flex lg:hidden`}
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  outline: 0;
`
const HeadLinks = styled.nav`
  ${tw`font-sans fixed w-full z-10`};
  padding: 10px 5%;
  background-color: ${colors['teal-darker']};
  top: 0;
  left: 0;
`

const Separator = styled.div`
  ${tw`h-px w-24 my-1 lg:h-6 lg:w-px lg:my-0`}
  background-color: ${colors.teal};
`

const AnchorsContainer = styled.div`
  ${tw`flex justify-between items-center w-full`};
  display: ${props => (props.isDesk || (props.burgerOpen && !props.isDesk) ? 'flex' : 'none')};
  flex-direction: ${props => props.direction};
`

const Anchor = styled(OutboundLink)`
  ${tw`sm:text-base lg:text-sm xl:text-base`}
  transition: color 0.2s ease-out;
  color: ${props => colors[props.color]};
  display: block;
  padding: ${props => (props.isDesk ? '0' : '4px 0')};
  &:hover {
    color: #fff;
  }
`
const LogoMobile = styled.div`
  display: ${props => (props.isDesk ? 'none' : 'block')};
`

const slickSettings = {
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  adaptiveHeight: false,
  fade: true,
  autoplay: false,
  autoplaySpeed: 3000,
}

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hash: hashLocation,
      isOpen: false,
      width: 0,
    }

    globalHistory.listen(history => {
      if (history.action === 'POP') {
        const newHash = history.location.hash
        this.setState({ hash: newHash })
        this.handleScroll(sections.findIndex(section => section.hash === newHash.substring(1)))
      }
    })

    this.parallax = React.createRef()

    this.handleScroll = which => {
      this.setState(prev => ({ isOpen: false }))
      const ref = this.parallax.current
      if (typeof which === 'string') {
        ref.scrollTo(sections[which])
      } else {
        ref.scrollTo(which)
      }
    }

    this.handleClick = () => {
      this.setState(prev => ({ isOpen: !prev.isOpen }))
    }

    this.handleResize = width => {
      this.setState({ width })
      this.setState({ isOpen: false })
    }
  }

  componentDidMount() {
    if (hashLocation) {
      this.handleScroll(sections.findIndex(section => section.hash === hashLocation.substring(1)))
    }
  }

  render() {
    return (
      <div>
        <WindowSizeListener onResize={windowSize => this.handleResize(windowSize.windowWidth)} />
        <HeadLinks>
          <MobileContainer>
            <LogoMobile isDesk={this.state.width > 900}>
              <Anchor href={`#${sections[0].hash}`} key={sections[0].hash} color={this.state.hash === `#${sections[0].hash}` ? 'white' : 'teal'}>
                <Logo color="#fff" size="tiny" />
              </Anchor>
            </LogoMobile>
            <Burger type="button" onClick={this.handleClick} isActive={this.state.isOpen} toggleButton={this.handleClick} buttonWidth={30} barColor="white" />
          </MobileContainer>
          <AnchorsContainer burgerOpen={!!this.state.isOpen} direction={this.state.isOpen ? 'column' : 'row'} isDesk={this.state.width > 900}>
            {sections.map((section, index) => {
              const { hash } = this.state
              const key = uniqueID('anchor-')

              return (
                <React.Fragment key={key}>
                  {index === 3 ? <Separator /> : null}
                  <Anchor href={`#${section.hash}`} color={hash === `#${section.hash}` ? 'white' : 'teal'}>
                    {section.logo ? this.state.width > 900 ? <Logo color="#fff" size="tiny" /> : section.name : section.name}
                  </Anchor>
                  {index === 8 ? <Separator /> : null}
                </React.Fragment>
              )
            })}
          </AnchorsContainer>
        </HeadLinks>

        <Layout>
          <CookieConsent />
          <Container>
            <Parallax pages={13} ref={this.parallax}>
              <Header offset={0} />
              <Intro offset={1} />
              <Keypoints slickSettings={slickSettings} offset={2} />
              <Desk slickSettings={slickSettings} offset={3} />
              <Room slickSettings={slickSettings} offset={4} />
              <Riddles slickSettings={slickSettings} offset={5} />
              <Security offset={6} />
              <Controls offset={7} />
              <Extra offset={8} />
              <Roadmap offset={9} />
              <Testimony offset={10} />
              <MoreInfos offset={11} />
              <Footer offset={12} />
            </Parallax>
          </Container>
        </Layout>
      </div>
    )
  }
}

export default Index
