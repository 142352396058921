import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rotateAnimation } from '../styles/animations'
import triangle from '../images/triangle.svg'

export const H1 = styled.h1`
  ${tw`text-3xl lg:text-5xl font-sans mb-8 w-full tracking-wide`};
  /* text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    ${rotateAnimation('4s')};
    left: -60px;
    top: 5px;
  } */
`

export const H2 = styled.h2`
  ${tw`text-2xl lg:text-4xl font-sans mt-6 lg:mt-4 mb-2 w-full tracking-wide`};
  /* text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15); */
`

export const H3 = styled.h3`
  ${tw`text-1xl lg:text-3xl font-sans mt-6 lg:mt-3 mb-2 w-full tracking-wide`};
  /* text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15); */
`

export const Subtitle = styled.p`
  ${tw`text-lg lg:text-xl font-sans mt-2 xxl:w-3/4 w-full`};
  /* text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2); */
`
