import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Divider, DividerMiddle } from '../elements/Dividers'
import Content from '../elements/Content'
import Inner from '../elements/Inner'
import ContentGrid from '../elements/ContentGrid'
import TextContainer from '../elements/TextContainer'
import ImageContainer from '../elements/ImageContainer'
import Image from '../elements/Image'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

import security from '../images/illu/security.png'

const Security = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "security" } }) {
          html
        }
      }
    `
  )
  return (
    <div>
      <DividerMiddle bg={`linear-gradient(to right, ${colors.yellow} 0%, ${colors.orange} 100%)`} speed={-0.2} offset={`${offset}.1`} factor={2} />
      <Content speed={0.4} offset={`${offset}.2`} factor={1}>
        <Inner variant="clean">
          <ContentGrid>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.html,
                }}
              />
            </TextContainer>
            <ImageContainer>
              <Image img={security} size="fluid" />
            </ImageContainer>
          </ContentGrid>
        </Inner>
      </Content>
      <Divider speed={0.1} offset={offset} factor={2}>
        <UpDown>
          <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
          <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.green} left="20%" top="90%" />
          <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="30%" />
          <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
        </UpDownWide>
        <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
        <SVG icon="circle" width={12} fill={colors.pink} left="80%" top="60%" />
        <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
      </Divider>
    </div>
  )
}

export default Security
