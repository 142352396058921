import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { colors } from '../../tailwind'

const ImageContainer = styled.div`
  ${tw`w-full lg:w-2/4 flex justify-center items-center p-4 rounded-lg`};
  /* background-color: ${colors.white}; */
`

export default ImageContainer
