import styled, { css } from 'styled-components'

import React from 'react'

const sizes = {
  fluid: css`
    width: 100%;
  `,
}

const Double = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    ${p => sizes[p.size]}
    &:nth-of-type(1) {
      position: absolute;
      z-index: 1;
    }
    &:nth-of-type(2) {
      position: relative;
      z-index: 0;
    }
  }
`
const Img = styled.img``

const ImageDouble = props => {
  const { imgtop, imgbg, size } = props
  return (
    <Double {...props}>
      <Img src={imgtop} alt="" />
      <Img src={imgbg} alt="" />
    </Double>
  )
}

export default ImageDouble
