import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Divider } from '../elements/Dividers'
import Content from '../elements/Content'
import Inner from '../elements/Inner'
import ContentGrid from '../elements/ContentGrid'
import TextContainer from '../elements/TextContainer'
import ImageContainer from '../elements/ImageContainer'
import Image from '../elements/Image'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

import extras from '../images/illu/extras.png'

const Extra = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "extra" } }) {
          html
        }
      }
    `
  )
  return (
    <div>
      <Divider bg={colors.teal} clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={offset} />
      <Divider speed={0.1} offset={offset}>
        <UpDown>
          <SVG icon="box" hiddenMobile width={6} fill={colors.blue} left="50%" top="75%" />
          <SVG icon="upDown" hiddenMobile width={24} fill={colors.orange} left="80%" top="80%" />
        </UpDown>
        <UpDownWide>
          <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.purple} left="5%" top="80%" />
          <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
          <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
        </UpDownWide>
        <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
        <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
      </Divider>
      <Content speed={0.4} offset={offset}>
        <Inner variant="clean">
          <ContentGrid>
            <ImageContainer>
              <Image img={extras} size="fluid" />
            </ImageContainer>
            <TextContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.html,
                }}
              />
            </TextContainer>
          </ContentGrid>
        </Inner>
      </Content>
    </div>
  )
}

export default Extra
