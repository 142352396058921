import React, { useState, useRef, useEffect } from 'react'
import ImageDouble from '../elements/ImageDouble'

const ImagesAnim = ({ images, bg }) => {
  const [count, setCount] = useState(0)

  useInterval(() => {
    if (count === images.length - 1) {
      setCount(0)
    } else {
      setCount(count + 1)
    }
  }, 1000)

  return (
    <>
      <ImageDouble imgtop={images[count]} imgbg={bg} size="fluid" />
    </>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export default ImagesAnim
