import styled, { css } from 'styled-components'

import React from 'react'

const sizes = {
  huge: css`
    height: 400px;
  `,
  big: css`
    height: 300px;
  `,
  middle: css`
    height: 200px;
  `,
  normal: css`
    height: 100px;
  `,
  tiny: css`
    height: 20px;
  `,
  fluid: css`
    width: 100%;
  `,
}

const Img = styled.img`
  ${p => sizes[p.size]}
`

const Image = props => {
  const { img, size } = props
  return <Img {...props} src={img} alt="" />
}

export default Image
