import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import tw from 'tailwind.macro'
import styled from 'styled-components'
import { Divider, DividerMiddle } from '../elements/Dividers'
import Content from '../elements/Content'
import Inner from '../elements/Inner'
import ContentGrid from '../elements/ContentGrid'
import TextContainer from '../elements/TextContainer'
import ImageContainer from '../elements/ImageContainer'
import Image from '../elements/Image'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'
import { H1, H2, Subtitle } from '../elements/Titles'
import Logo from '../elements/Logo'

import teamwork from '../images/illu/teamwork.png'

const Intro = ({ offset }) => (
  <div>
    <Divider bg={`linear-gradient(to right, ${colors.teal} 0%, ${colors['teal-darker']} 100%)`} clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={offset} />
    <Divider speed={0.1} offset={offset}>
      {/* <UpDown>
        <SVG icon="diamond" file hiddenMobile width={16} left="50%" top="75%" />
        <SVG icon="face_1" hiddenMobile width={8} fill={colors.orange} left="70%" top="20%" />
        <SVG icon="face" width={12} fill={colors.red} left="25%" top="5%" />
        <SVG icon="festival" file hiddenMobile width={24} left="80%" top="80%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="locator" file hiddenMobile width={16} left="5%" top="80%" />
        <SVG icon="game" file width={16} left="95%" top="50%" />
        <SVG icon="bulb" file width={16} left="85%" top="15%" />
        <SVG icon="message" file hiddenMobile width={24} left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="time" file width={12} left="4%" top="20%" />
      <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
      <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
      <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" /> */}
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Inner variant="clean">
        <ContentGrid>
          <ImageContainer>
            <Image img={teamwork} size="fluid" />
          </ImageContainer>
          <TextContainer>
            <H1>
              Gestion de salle novateur et efficace.
              <br />
              <br />
              <Logo color={colors['teal-darker']} size="small" />
            </H1>
          </TextContainer>
        </ContentGrid>
      </Inner>
    </Content>
    <DividerMiddle bg={`linear-gradient(to right, ${colors.orange} 0%, ${colors.yellow} 100%)`} speed={-0.2} offset={`${offset}.1`} factor={2} />
  </div>
)

export default Intro
