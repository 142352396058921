import React from 'react'
import Slider from 'react-slick'
import { H2, H3, Subtitle } from '../elements/Titles'
import { UpDown, UpDownWide } from '../styles/animations'

import Content from '../elements/Content'
import ContentGrid from '../elements/ContentGrid'
import { Divider } from '../elements/Dividers'
import ImageContainer from '../elements/ImageContainer'
import ImageDouble from '../elements/ImageDouble'
import Inner from '../elements/Inner'
import SVG from '../components/SVG'
import TextContainer from '../elements/TextContainer'
import { colors } from '../../tailwind'
import room from '../images/isorooms/room.png'
import roomEnigme01 from '../images/isorooms/rooms_enigme_01.png'
import roomEnigme02 from '../images/isorooms/rooms_enigme_02.png'

const Riddles = ({ slickSettings, offset }) => (
  <div>
    <Divider speed={0.2} offset={offset}>
      {/* <UpDown>
        <SVG icon="triangle" hiddenMobile width={48} stroke={colors.orange} left="10%" top="20%" />
        <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
        <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
        <SVG icon="circle" width={16} fill={colors['grey-darker']} left="70%" top="90%" />
        <SVG icon="triangle" width={16} stroke={colors['grey-darkest']} left="30%" top="65%" />
        <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="circle" hiddenMobile width={24} fill={colors['grey-darker']} left="5%" top="70%" />
      <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="4%" top="20%" />
      <SVG icon="box" width={6} fill={colors['grey-darkest']} left="10%" top="10%" />
      <SVG icon="box" width={12} fill={colors['grey-darkest']} left="40%" top="30%" /> */}
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Inner variant="clean">
        <Slider {...slickSettings}>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomEnigme01} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H2>Des énigmes plus poussées</H2>
                <Subtitle>Meilleure répartition spatiale.</Subtitle>
                <Subtitle>Réseau, props communicants.</Subtitle>
                <Subtitle>Les éléments actifs des énigmes peuvent être dispersées sur la salle.</Subtitle>
                <Subtitle>Les props peuvent communiquer entre eux donc interagir et se compléter.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
          <div>
            <ContentGrid>
              <ImageContainer>
                <ImageDouble imgtop={roomEnigme02} imgbg={room} size="fluid" />
              </ImageContainer>
              <TextContainer>
                <H3>Système réglable</H3>
                <Subtitle>Possibilité de modifier les comportements des énigmes (niveaux de difficulté, i18N des sons et textes).</Subtitle>
                <Subtitle>Éléments sans fil (boites, livres, ...) intégrables et en réseau.</Subtitle>
              </TextContainer>
            </ContentGrid>
          </div>
        </Slider>
      </Inner>
    </Content>
  </div>
)

export default Riddles
