import React from 'react'
import Logo from '../elements/Logo'
import { colors } from '../../tailwind'

import Content from '../elements/Content'

const Header = ({ offset }) => (
  <header>
    <Content speed={0.4} offset={offset}>
      <Logo color={colors.yellow} size="max-fluid" />
    </Content>
  </header>
)

export default Header
